<template>
  <AbstractFilter
    :on-filter="filterQuizzes"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="quiz_new"
    filter-panel-class="filter-panel-quiz"
  >
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('quiz.filter.title')"
    />
    <Select
      v-model="filter.layout"
      :options="layoutOptions"
      :label="$t('quiz.filter.layout')"
      id="filter_layout"
    />
    <Datepicker
      type="date"
      v-model="filter.createdAtFrom"
      id="filter_createdAtFrom"
      :label="$t('quiz.filter.created_at_from')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('quiz.filter.id')"
    />
  </AbstractFilter>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import QuizFilterModel from '@/model/QuizFilter'
import AbstractFilter from '@/components/filter/AbstractFilter'
import { AVAILABLE_LAYOUTS } from '@/model/ValueObject/QuizLayout'
import Datepicker from '@/components/form/Datepicker'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'QuizFilter',
  data () {
    return {
      filter: this._.cloneDeep(QuizFilterModel),
      layoutOptions: AVAILABLE_LAYOUTS,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.QUIZ_PERMISSIONS
    }
  },
  components: {
    Input,
    Select,
    Datepicker,
    AbstractFilter
  },
  methods: {
    filterQuizzes () {
      this.$store.commit('quiz/setPage', 1)
      this.$store.commit('quiz/setFilter', this.filter)
      this.$store.dispatch('quiz/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(QuizFilterModel)
      this.$store.commit('quiz/setFilter', this.filter)
      this.$store.dispatch('quiz/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['quiz/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-quiz {
  @include bp(7) {
    grid-template-columns: 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
