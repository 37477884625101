<template>
  <section>
    <QuizFilter />
    <Preloader v-if="callingAPI" />
    <QuizTable
      :quizzes="quizzes"
      :totalCount="totalCount"
      :current-page="page"
      :on-page-change="setPageAndGetRecords"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import QuizFilter from '@/components/filter/FilterQuiz'
import QuizTable from '@/components/table/QuizTable'

export default {
  name: 'QuizListView',
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    quizzes () {
      return this.$store.getters['quiz/list']
    },
    totalCount () {
      return this.$store.getters['quiz/totalCount']
    },
    page () {
      return this.$store.getters['quiz/page']
    }
  },
  components: {
    QuizTable,
    Preloader,
    QuizFilter
  },
  methods: {
    getQuizzes () {
      this.$store.dispatch('quiz/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('quiz/setPage', page)
      this.getQuizzes()
    }
  },
  mounted () {
    this.getQuizzes()
  }
}
</script>
